import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hint", "hintLength", "hintCase", "hintDigit", "hintSpecial"]
  static classes = ["valid", "error"]

  connect() {
    this.validateOnFormSubmission()
  }

  updateHints(e) {
    this.hintTargets.forEach((hint) => { hint.classList.remove(this.errorClass) })

    this.hintLengthTarget.classList.toggle(this.validClass, this.checkLength())
    this.hintCaseTarget.classList.toggle(this.validClass, this.checkCase())
    this.hintDigitTarget.classList.toggle(this.validClass, this.checkDigit())
    this.hintSpecialTarget.classList.toggle(this.validClass, this.checkSpecial())
  }

  checkLength() {
    return this.inputTarget.value.length >= 9
  }

  checkCase() {
    return this.inputTarget.value.match(/(?=.*?[A-Z])(?=.*?[a-z])/)
  }

  checkDigit() {
    return this.inputTarget.value.match(/(?=.*?[0-9])/)
  }

  checkSpecial() {
    return this.inputTarget.value.match(/(?=.*?[#?!@$%^&\-_\/\\*=+><,;.:()§°"'])/)
  }

  validateOnFormSubmission() {
    let form = this.inputTarget.closest("form")

    form.addEventListener("submit", (e) => {
      this.hasErrors = false

      this.validateCriteria(this.hintLengthTarget, this.checkLength())
      this.validateCriteria(this.hintCaseTarget, this.checkCase())
      this.validateCriteria(this.hintDigitTarget, this.checkDigit())
      this.validateCriteria(this.hintSpecialTarget, this.checkSpecial())

      if(this.hasErrors) {
        e.preventDefault()

        // We re-enable the form submit button
        let submitButton = form.querySelector("[type=submit]")
        if (submitButton) {
          submitButton.dataset.loader = "false"
          submitButton.removeAttribute("data-disable-with")
          submitButton.disabled = false
        }
      }
    })
  }

  validateCriteria(hintTarget, isValid) {
    if(!isValid) {
      hintTarget.classList.add(this.errorClass)
      this.hasErrors = true
    }
  }
}
