import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["input", "list", "listItemTemplate"]

  async connect() {
    this.closeList()
    this.collection = await get(this.collectionUrl, { responseKind: "json" }).then(response => response.json)
  }

  update() {
    this.renderList()
  }

  select(event) {
    const element = this.collection.find(item => item.id === Number.parseInt(event.currentTarget.dataset.id))

    this.inputTarget.value = ""
    this.renderList()

    this.itemFields.forEach(field => {
      this.element.querySelector(`[data-item-${field}]`).value = element[field]
      this.element.querySelector(`[data-item-${field}]`).dispatchEvent(new Event("change"))
    })
    if (this.unitPriceFormat === "ht") {
      this.element.querySelector(`[data-item-price]`).value = element.price_ht
    } else {
      this.element.querySelector(`[data-item-price]`).value = element.price_ttc
    }
  }

  closeList() {
    this.listTarget.classList.add("d-none")
  }

  // Private

  renderList() {
    this.listTarget.innerHTML = ""
    this.listTarget.classList.toggle("d-none", !this.itemsMatchingQuery.length)

    this.itemsMatchingQuery.forEach(element => {
      const listItem = this.listItemTemplateTarget.content.cloneNode(true).firstElementChild

      this.templateFields.forEach(field => {
        listItem.querySelector(`[data-template-${field}]`).innerHTML = element[field]
      })

      listItem.dataset.id = element.id

      this.listTarget.appendChild(listItem)
    })
  }

  get itemsMatchingQuery() {
    if (this.query.length === 0) { return [] }

    return this.collection.filter(item => item.name.toLowerCase().includes(this.query.toLowerCase()))
  }

  get templateFields() {
    return JSON.parse(this.data.get("templateFields"))
  }

  get itemFields() {
    return JSON.parse(this.data.get("itemFields"))
  }

  get query() {
    return this.inputTarget.value
  }

  get collectionUrl() {
    return this.data.get("url")
  }

  get unitPriceFormat() {
    return this.data.get("unitPriceFormat")
  }
}
