import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = {
    items: Array,
    initCarousel: {type: Boolean, default: true}
  }

  instance = null;

  connect() {
    if (this.initCarouselValue) {
      this.initializeMagnificPopup();
    } else {
      $(this.element).find(".img-container a").on("click", (e) => {
        e.preventDefault();
      });
    }
  }

  initializeMagnificPopup() {
    this.instance = $(this.buttonTarget).magnificPopup({
      type: "image",
      items: this.itemsValue, // Accessing the items from the values API
      gallery: {
        enabled: true,
        navigateByImgClick: true
      },
      titleSrc: "title",
    });

    $(this.element).find(".img-container a").each((index, el) => {
      $(el).on("click", (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.instance.magnificPopup("open").magnificPopup("goTo", index);
      });
    });
  }

  removeFocus() {
    this.buttonTarget.blur();
  }

  disconnect() {
    this.instance.magnificPopup("destroy");
    $(this.element).find(".img-container a").each((index, el) => {
      $(el).off("click");
    });
  }
}
