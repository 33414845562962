export const numberToCurrency = (number) => {
  const formatter = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
  })

  return formatter.format(number)
}

export const currencyToNumber = (currencyString) => {
  return parseFloat(
    currencyString
      .replace(/\s/g, '') // Supprime les espaces
      .replace('€', '')   // Supprime le symbole de la devise
      .replace(',', '.')  // Remplace la virgule par un point
  );
};