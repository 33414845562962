import { Controller } from "@hotwired/stimulus"
import { currencyToNumber } from "../helpers/currency_helpers"

export default class extends Controller {
  static targets = [
    "discountExclTax",
    "discountInclTax",
    "unitPrice",
    "taxCode",
    "quantity",
    "totalExclTax",
    "totalInclTax",
    "offerItem",
    "toggleButton",
    "toggleButtonContainer",
    "discountBlock",
    "discountExclTaxWrapper",
    "discountInclTaxWrapper"
  ]

  static values = {
    compoundedTaxRates: Array,
    taxRates: Object
  }

  connect() {
    this.initDiscountFieldsState()
    this.checkExistingDiscounts()
  }

  // Vérifie si des remises sont déjà définies au chargement
  checkExistingDiscounts() {
    if (this.hasDiscount) this.showInputsAndHideButton()
  }

  initDiscountFieldsState() {
    const isValid = this.areFieldsValid
    this.toggleButtonState(isValid)
    this.applyFieldRules()
  }

  applyFieldRules() {
    if (this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
      this.enableDiscountFields()
    } else {
      this.toggleFieldState(this.discountExclTaxTarget, this.discountExclTaxWrapperTarget, this.unitPriceFormat === "ttc")
      this.toggleFieldState(this.discountInclTaxTarget, this.discountInclTaxWrapperTarget, this.unitPriceFormat === "ht")
    }
  }

  toggleButtonState(isEnabled) {
    this.toggleButtonTarget.disabled = !isEnabled
    if (isEnabled) {
      this.toggleButtonTarget.removeAttribute("data-original-title")
      this.toggleButtonTarget.removeAttribute("title")
    } else {
      this.toggleButtonTarget.setAttribute(
        "data-original-title",
        "Le prix total de l'article doit être positif pour apliquer une remise."
      )
    }
  }

  // Vérifie et met à jour les discounts dynamiquement
  checkAndUpdateDiscounts() {
    if (this.areFieldsValid)  {
      this.copyTotalsToDiscounts()
    }
    this.calculateDiscounts()
  }

  // Gestion de l'état de la case "Offrir l'article"
  toggleOfferItem() {
    if (this.offerItemTarget.checked) {
      this.copyTotalsToDiscounts() // Met à jour les montants
      if (!this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
        this.disableDiscountFields() // Désactive les champs
      }
    } else {
      this.resetFields()
    }
  }

  // Réinitialise les champs de remise
  resetFields() {
    this.cleanDiscountFields()
    this.enableDiscountFields()
    this.initDiscountFieldsState()
  }

  trashDiscountLine() {
    this.resetFields()
    this.offerItemTarget.checked = false
    this.hideInputsAndShowButton()
  }

  // Affiche la ligne de remise
  showInputsAndHideButton() {
    this.toggleButtonContainerTarget.classList.add("d-none")
    this.discountBlockTarget.classList.remove("d-none")
    this.discountBlockTarget.classList.add("border", "p-2", "rounded")
  }

  hideInputsAndShowButton() {
    this.discountBlockTarget.classList.add("d-none")
    this.discountBlockTarget.classList.remove("border", "p-2", "rounded")
    this.toggleButtonContainerTarget.classList.remove("d-none")
  }

  // Vide les champs de remise
  cleanDiscountFields() {
    this.discountExclTaxTarget.value = ""
    this.discountInclTaxTarget.value = ""
  }

  // Désactive les champs de remise
  disableDiscountFields() {
    this.toggleFieldState(this.discountExclTaxTarget, this.discountExclTaxWrapperTarget, true)
    this.toggleFieldState(this.discountInclTaxTarget, this.discountInclTaxWrapperTarget, true)
  }

  // Active les champs de remise
  enableDiscountFields() {
    this.toggleFieldState(this.discountExclTaxTarget, this.discountExclTaxWrapperTarget, false)
    this.toggleFieldState(this.discountInclTaxTarget, this.discountInclTaxWrapperTarget, false)
  }

  // Active/Désactive un champ avec style et état en lecture seule
  toggleFieldState(target, wrapperTarget, isDisabled) {
    const action = isDisabled ? "add" : "remove"
    wrapperTarget.classList[action]("input-suffix__200")
    target.classList[action]("quiet")
    target.readOnly = isDisabled
    if (this.isOffered()) {
      this.offerItemTarget.checked = true
    }
  }

  isOffered() {
    return (
      parseFloat(this.discountExclTaxTarget.value) == currencyToNumber(this.totalExclTaxTarget.value) &&
      parseFloat(this.discountInclTaxTarget.value) == currencyToNumber(this.totalInclTaxTarget.value)
    )
  }

  // Copie les totaux dans les discounts
  copyTotalsToDiscounts() {
    if (this.offerItemTarget.checked) {
      this.discountExclTaxTarget.value = this.parseNumber(this.totalExclTaxTarget.value)
      this.discountInclTaxTarget.value = this.parseNumber(this.totalInclTaxTarget.value)
    }
  }

  // Calcul des remises
  calculateDiscounts() {
    if (!this.compoundedTaxRatesValue.includes(this.taxCodeValue)) {
      if (this.unitPriceFormat === "ht") {
        const discountExclTax = parseFloat(this.discountExclTaxTarget.value) || 0
        const discountInclTax = discountExclTax * (1 + this.taxRate)
        this.discountInclTaxTarget.value = discountInclTax.toFixed(2)
      } else {
        const discountInclTax = parseFloat(this.discountInclTaxTarget.value) || 0
        const discountExclTax = discountInclTax / (1 + this.taxRate)
        this.discountExclTaxTarget.value = discountExclTax.toFixed(2)
      }
    }
  }

  // Formate les nombres avec 2 décimales
  parseNumber(value) {
    return parseFloat(value.replace(",", ".").replace(/[^\d.-]/g, "")).toFixed(2) || 0
  }

  get unitPriceFormat() {
    return this.data.get("unitPriceFormat")
  }

  get taxCodeValue() {
    return this.taxCodeTarget.value.trim()
  }

  get taxRate() {
    return +this.taxRatesValue[this.taxCodeValue]
  }

  get hasDiscount() {
    return (
      (this.discountExclTaxTarget.value && parseFloat(this.discountExclTaxTarget.value) > 0) ||
      (this.discountInclTaxTarget.value && parseFloat(this.discountInclTaxTarget.value) > 0)
    )
  }

  get unitPriceValue() {
    return parseFloat(this.unitPriceTarget.value.trim()) || 0
  }

  get quantityValue() {
    return parseFloat(this.quantityTarget.value.trim()) || 0
  }

  get areFieldsValid() {
    return this.unitPriceValue > 0 && this.taxCodeValue !== "" && this.quantityValue > 0
  }
}