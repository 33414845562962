import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "budgetTypeRadio","budgetSection", "budgetTypeTab", "budgetFlexibleRadio",
    "budgetFlexibleTab", "priceSuggestionAlerts", "budgetUpdateReasonWrapper", "budgetUpdateReasonInput",
    "eventTypeSelect", "budgetWrapper", "globalBudgetMaxInput", "globalBudgetInput", "budgetPerPersonInput",
  ]

  connect() {
    const activeBudgetType = this.budgetWrapperTarget.dataset["requestQuotesActiveBudgetType"]

    this.data.set("activeBudgetType", activeBudgetType)
    this.toggleBudgetTypeTabs()
    this.toggleBudgetFieldsVisibility()
    this.toggleBudgetFlexibleFieldsVisibility()
  }

  toggleBudgetTypeTabsVisibility(event) {
    this.data.set("activeBudgetType", event.target.id)
    this.toggleBudgetTypeTabs()
  }

  toggleBudgetFieldsVisibility() {
    this.budgetTypeSelected() ? this.showBudgetFields() : this.hideBudgetFields()
  }

  toggleBudgetFlexibleFieldsVisibility() {
    this.budgetFlexible() ? this.showBudgetFlexibleFields() : this.hideBudgetFlexibleFields()
  }

  togglePriceSuggestionAlert(event) {
    if (!this.hasPriceSuggestionAlertsTarget) return

    const checkPath = this.priceSuggestionAlertsTarget?.dataset?.checkPath
    if (!checkPath || event.target.value == "") return

    const params = {
      event: {
        first_budget: {
          amount: parseInt(event.target.value),
          type: this.data.get("activeBudgetType").replace("event_budget_type_budget_", "")
        }
      }
    }
    post(checkPath, { responseKind: "turbo-stream", body: JSON.stringify(params) })
  }

  toggleBudgetUpdateReasonVisibility(event) {
    if (this.hasBudgetUpdateReasonWrapperTarget) {
      var shouldDisplayBudgetUpdateReason = false
      const budgetType = this.budgetTypeRadioTargets.find(radio => radio.checked)?.value

      if (budgetType === "budget_global") {
        shouldDisplayBudgetUpdateReason = this.globalBudgetInputTarget.value < parseInt(this.globalBudgetInputTarget.dataset["previousBudgetValue"])
      } else if (budgetType === "budget_per_person") {
        shouldDisplayBudgetUpdateReason = this.budgetPerPersonInputTarget.value < parseInt(this.budgetPerPersonInputTarget.dataset["previousBudgetValue"])
      }

      if (shouldDisplayBudgetUpdateReason) {
        this.budgetUpdateReasonWrapperTarget.classList.remove("d-none")
      } else {
        this.budgetUpdateReasonWrapperTarget.classList.add("d-none")
        this.budgetUpdateReasonInputTarget.value = ""
        this.budgetUpdateReasonInputTarget.setAttribute("value", "")
      }
    }
  }

  // Private

  toggleBudgetTypeTabs() {
    this.budgetTypeTabTargets.forEach(tab => {
      if (this.data.get("activeBudgetType") === tab.dataset.toggleTabName) {
        tab.classList.remove("d-none")
      } else {
        tab.classList.add("d-none")
      }
    })
  }

  budgetTypeSelected() {
    return !!this.budgetTypeRadioTargets.find(radio => radio.checked)
  }

  showBudgetFields() {
    this.budgetSectionTarget.classList.remove("d-none")
  }

  hideBudgetFields() {
    this.budgetSectionTarget.classList.add("d-none")
  }

  budgetFlexible() {
    const checkedRadio = this.budgetFlexibleRadioTargets.find(radio => radio.checked)
    return checkedRadio && checkedRadio.value == "true"
  }

  showBudgetFlexibleFields() {
    this.budgetFlexibleTabTarget.classList.remove("d-none")
    if(this.hasGlobalBudgetMaxInputTarget) {
      this.globalBudgetMaxInputTarget.disabled = false
    }
  }

  hideBudgetFlexibleFields() {
    this.budgetFlexibleTabTarget.classList.add("d-none")
    if(this.hasGlobalBudgetMaxInputTarget) {
      this.globalBudgetMaxInputTarget.disabled = true
    }
  }

  updateEventTypeSelectFromCategorySelect(event) {
    const options = Array.from(event.target.options)
    const selectedOption = options.find(option => option.value == event.target.value)
    const url = selectedOption.dataset["url"]

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

}
