import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'

/**
 * This controller manages the search bar in the navbar.
 * The targets allow the NavbarSearchForm controller to target DOM elements for updates.
 * The actions allow communication with the NavbarSearchForm controller and focus the correct elements
 * in the modal based on the element targeted by the user in the navbar.
**/

export default class extends Controller {
  static targets = ["queryButton", "packageCategoryButton", "guestsButton", "queryContainer", "packageCategoryDivider", "packageCategoryContainer"]
  static outlets = ["navbar-search-form"]
  static debounces = ["focusQueryInput", "focusPackageCategoryInput", "focusGuestsInput"];

  connect() {
    useDebounce(this, { wait: 500 })
  }

  focusQueryInput() {
    const target = this.navbarSearchFormOutlet.queryTarget
    target.focus()
    target.selectionStart = target.selectionEnd = target.value.length
  }

  focusPackageCategoryInput() {
    this.navbarSearchFormOutlet.packageCategoryInputTarget.focus()
  }

  focusGuestsInput() {
    this.navbarSearchFormOutlet.guestsInputTarget.focus()
  }
}
